import 'greensock';

import {displayHtmlInit} from "./modules/displayContent";
import {buttonInit} from "./modules/buttonInit";
import {fixedNavInit} from "./modules/fixedNav";

function init() {
    displayHtmlInit();
    buttonInit();
    fixedNavInit();
}

init();