export function fixedNavInit() {

    let $topNavHeight = $('.menu-top-nav-container').outerHeight();

    $(window).scroll(function () {
        //Variable
        const $navbar = $('.navbar');

        if ($(this).scrollTop() >= 100) {

            TweenMax.to($navbar, .3, {
                y: '-' + $topNavHeight,
            });

        } else {
            TweenMax.to($navbar, .3, {
                y: '0',
            });
        }

    });

}