export function buttonInit() {

    //Variables
    const $button = $('button, .bo-miniCart__bg');
    const $background = $('.bo-miniCart__bg');
    const $sideNav = $('.bo-miniCart__content');
    const $wrapper = $('.bo-miniCart');

    $button.click(function () {

            if ($(this).data('btn') == 'shoppingCart') {
                if ($wrapper.hasClass('js-active')) {

                    $wrapper.removeClass('js-active');

                    TweenMax.fromTo($sideNav, .6, {
                        x: '0%'
                    }, {
                        x: '100%',
                        ease: Power3.easeInOut
                    });

                    TweenMax.fromTo($background, .6, {
                        opacity: '1',
                        pointerEvents: 'auto',
                    }, {
                        opacity: '0',
                        pointerEvents: 'none',
                        ease: Power3.easeInOut
                    });

                    setTimeout(function () {
                        $wrapper.css('display', 'none');
                    }, 600)
                } else {
                    $(this).closest('body').find('.bo-miniCart').addClass('js-active');

                    $wrapper.css('display', 'block');

                    TweenMax.fromTo($sideNav, .6, {
                        x: '100%'
                    }, {
                        x: '0%',
                        ease: Power3.easeInOut
                    });

                    TweenMax.fromTo($background, .6, {
                        opacity: '0',
                        pointerEvents: 'none',
                    }, {
                        opacity: '1',
                        pointerEvents: 'auto',
                        ease: Power3.easeInOut
                    });
                }
            } else if ($(this).data('btn') == 'burgerMenu') {
                if ($(this).hasClass('js-active')) {
                    $(this).removeClass('js-active');

                    $('.menu-menu-principal-container').slideUp();

                } else {
                    $(this).addClass('js-active');
                    $('.menu-menu-principal-container').slideDown();
                }
            }

        }
    );

}


//FIX pour la navbar
function navbarFIx() {
    if ($(window).width() > 992) {
        $('.menu-menu-principal-container').show();
    } else {
    }
}

$(window).resize(navbarFIx);



